<template>
  <div class="place-page">

    <h1>My gym</h1>

    <div class="table-responsive">
      <table class="table">
        <thead class="table-light small">
        <tr>
          <th width="1%">ID</th>
          <th>Title</th>
          <th class="text-center">Price</th>
          <th>Address</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody v-if="placeList?.length > 0">
          <tr v-for="placeData in placeList" :key="placeData.id">
            <td class="text-nowrap">{{ placeData.id }}</td>
            <td>
              <router-link :to="{path: '/place/partner/' + placeData.id}">{{ placeData.title }}</router-link>
            </td>
            <td class="text-center">
              {{ this.getPrice(placeData.price) }}{{ this.getCurrencySymbol() }}
            </td>
            <td>{{ placeData.address }}</td>
            <td>
              <span class="badge bg-secondary">
                {{ placeStatusEnum.getLabels()[placeData.status] }}
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="100%" class="text-center">No records found</td>
          </tr>
        </tbody>
      </table>
    </div>

    <pager-nav :pager="placePager" :route="'/place/partner'" @onPagerUpdate="onPagerUpdate"></pager-nav>

  </div>
</template>

<script>
import PagerNav from "@/components/PagerNav";
import {PlaceStatusEnum} from "@/modules/place/enums/PlaceEnum";

export default {
  components: {PagerNav},

  data: () => ({
    placeList: [],
    placePager: {},
    placeStatusEnum: PlaceStatusEnum
  }),

  async mounted() {

    if (!await this.checkPartner())
      return;

    this.setBreadcrumbs();

    await this.doPlacePartnerIndex();
  },

  methods: {

    async onPagerUpdate() {

      await this.doPlacePartnerIndex();
    },

    async doPlacePartnerIndex() {

      let apiHolder = await this.sendApiGet(['api/place/partner/index', {
        page: this.$route.query?.page || 1
      }]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.placeList)
        this.placeList = apiHolder.data.placeList;
      if (apiHolder.data?.placePager)
        this.placePager = apiHolder.data.placePager;
    },

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        ['Partner panel', '/partner'],
        'My gym'
      ]);
    }

  }

}
</script>

<style scoped>

</style>